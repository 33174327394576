<template>
    <SmartTable url="/document/category/index" base_path="/document/category"></SmartTable>
</template>
<script>
import SmartTable from "@/view/components/SmartTable.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    components: {
        SmartTable
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Documenti", route: "/document" },
            { title: "Categorie", route: "/document/category/index" },
            { title: "Ricerca Categorie" }
        ]);
    },
};
</script>
